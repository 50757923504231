import axios from 'axios'

export class Nota {

    //axios.get('/professor/horario?servidor_id='+servidor_id+'&ano='+ano)

    static async notaAluno (id) {
        return axios.get('nota/aluno'+id);
    }
    static async notaAlunoDisciplina (payload) {
        return axios.get('nota/aluno/disciplina/'+payload.aluno_notas_id+'/'+payload.disciplina_id);
    }
    static async buscarIdNotaAluno (payload) {
        return axios.get('/nota/aluno/'+payload.aluno_id+'/'+payload.ano);
    }
    static async NotaAlunos (turma_id) {
        return axios.get('/nota/alunos/turma/'+turma_id);
    }
    static async adicionarNotaAluno (payload) {
        return axios.post('nota/aluno', payload);
    }
    static async adicionarDeTodosAlunos (array) {
        return axios.post('nota/alunos/disciplina/add',  {
            array: array,
        });
    }
    static async adicionarNotaAlunoDisciplina (payload) {
        return axios.post('nota/aluno/disciplina', payload);
    }
    static async editarNotaAlunoDisciplina (payload) {
        return axios.put('nota/aluno/disciplina',payload);
    }
    static async GetNotaConfig (payload) {
        return axios.post('nota/config', payload);
    }
    static async AddAvaliacao (payload) {
        return axios.post('nota/avaliacao', payload);
    }
    static async AddRecuperacao (payload) {
        return axios.post('nota/recuperacao', payload);
    }
    static async AddAtividadesComplementares (payload) {
        return axios.post('nota/atividades', payload);
    }
    static async AddSimulado (payload) {
        return axios.post('nota/simulado', payload);
    }
    static async AddNotaConfig (payload) {
        return axios.put('nota/config', payload);
    }

    static async ListarNotaAlunos (turma,disciplina,ano) {
        return axios.get('notasalunos/'+turma+'/'+disciplina+'/'+ano);
    }
    static async GetConfigPeriodo (turma,disciplina) {
        return axios.get('nota/config/periodo/'+turma+'/'+disciplina);
    }
    static async buscarAtividadesAvaliativaAluno (payload) {
        return axios.post('nota/atividades/get', payload);
    }
    static async buscarAvaliacoesAluno(payload) {
        return axios.post('nota/avaliacao/get', payload);
    }

    static async EncerrarPeriodo (payload) {
        return axios.post('nota/periodo/encerrar', payload);
    }
    static async buscarHistorioDeNotasDeAluno (payload) {
        let ano = sessionStorage.getItem("anoSelecionado");
        return axios.get('notas-turma-historico/'+ payload.periodo +'/' +  payload.aluno_id + '/' +  payload.disciplina_id + '/' + payload.turma_atual_id+'/'+ano);
    }
    
    // Funcoes Auxiliares de CALCULO
    /*Obs: Para fundamental 1 apenas somar a nota do primeiro e do segundo periodo, ele passa se for maior que ou igual a 100. Caso Ele tbm pode fazer um provao valendo 200*/


    //Bimestre
    static async calcMediaBimestral (record, periodo, substituiOque, configuracao) {
        var atividade = 0, simulado = 0, avaliacao = 0, recuperacao = 0;
        if (periodo == 1) {
            if (record.periodo1_atividades != null && configuracao.info.tera_atividades) {
                atividade = parseInt(record.periodo1_atividades[0].atividade);
            }
            if (record.periodo1_simulado != null && configuracao.info.tera_simulado) {
                simulado = parseInt(record.periodo1_simulado.simulado);
            }
            if (record.periodo1_avaliacao != null && configuracao.info.tera_avaliacao) {
                avaliacao = parseInt(record.periodo1_avaliacao.avaliacao);
            }
            if (record.periodo1_recuperacao != null && configuracao.info.tera_recuperacao) {
                recuperacao = parseInt(record.periodo1_recuperacao.recuperacao);
            }
        }
        else if (periodo == 2) {
            if (record.periodo2_atividades != null && configuracao.info.tera_atividades) {
                atividade = parseInt(record.periodo2_atividades[0].atividade);
            }
            if (record.periodo2_simulado != null && configuracao.info.tera_simulado) {
                simulado = parseInt(record.periodo2_simulado.simulado);
            }
            if (record.periodo2_avaliacao != null && configuracao.info.tera_avaliacao) {
                avaliacao = parseInt(record.periodo2_avaliacao.avaliacao);
            }
            if (record.periodo2_recuperacao != null && configuracao.info.tera_recuperacao) {
                recuperacao = parseInt(record.periodo2_recuperacao.recuperacao);
            }

        }
        else if (periodo == 3) {
            if (record.periodo3_atividades != null && configuracao.info.tera_atividades) {
                atividade = parseInt(record.periodo3_atividades[0].atividade);
            }
            if (record.periodo3_simulado != null && configuracao.info.tera_simulado) {
                simulado = parseInt(record.periodo3_simulado.simulado);
            }
            if (record.periodo3_avaliacao != null && configuracao.info.tera_avaliacao) {
                avaliacao = parseInt(record.periodo3_avaliacao.avaliacao);
            }
            if (record.periodo3_recuperacao != null && configuracao.info.tera_recuperacao) {
                recuperacao = parseInt(record.periodo3_recuperacao.recuperacao);
            }
        }
        else if (periodo == 4) {
            if (record.periodo4_atividades != null && configuracao.info.tera_atividades) {
                atividade = parseInt(record.periodo4_atividades[0].atividade);
            }
            if (record.periodo4_simulado != null  && configuracao.info.tera_simulado) {
                simulado = parseInt(record.periodo4_simulado.simulado);
            }
            if (record.periodo4_avaliacao != null  && configuracao.info.tera_avaliacao) {
                avaliacao = parseInt(record.periodo4_avaliacao.avaliacao);
            }
            if (record.periodo4_recuperacao != null  && configuracao.info.tera_recuperacao) {
                recuperacao = parseInt(record.periodo4_recuperacao.recuperacao);
            }
        }
        // a recuperacao substitui tudo.
        if (substituiOque == 7)
            return  Math.max(atividade + simulado + avaliacao, recuperacao);
        //  a recuperacao substitui atividade e avaliacao.
        if (substituiOque == 6)
            return  simulado + Math.max(atividade + avaliacao, recuperacao);
        //  a recuperacao substitui simulado e avaliacao.
        if (substituiOque == 5)
            return  atividade + Math.max(simulado + avaliacao, recuperacao);
        //  a recuperacao substitui atividade e simulado.
        if (substituiOque == 4)
            return  avaliacao + Math.max(atividade + simulado, recuperacao);
        //  a recuperacao substitui a avaliacao.
        if (substituiOque == 3)
            return  simulado + atividade + Math.max(avaliacao, recuperacao);
        //  a recuperacao substitui a atividade.
        if (substituiOque == 2)
            return  simulado + avaliacao + Math.max(atividade, recuperacao);
        //  a recuperacao substitui o simulado
        if (substituiOque == 1)
            return atividade + avaliacao + Math.max(simulado, recuperacao);
    }
    static async caclMediaFinalTipoBimestral (total, record, tipoTabela) {
        // console.log(total, record, tipoTabela);
        var recuperacao = 0, totalMedia = 0;
        if (tipoTabela == 3)// se for eja tem apenas dois periodos
            totalMedia = Math.round(parseInt(total) / 2);
        else
            totalMedia = Math.round(parseInt(total) / 4); // se nao for eja tem mais

        if (record.aluno_notas_completa_existe) {
            if (record.aluno_notas_completa.recuperacao_final != null)
                recuperacao = parseInt(record.aluno_notas_completa.recuperacao_final);
        }
        return  Math.max(totalMedia, recuperacao);
    }

    static async VisuPeriodosEncerrar () {
        let ano = sessionStorage.getItem("anoSelecionado");
        return axios.get('nota/periodo/encerrar/visu/'+ano);
    }
    static async EncerrarPeriodos (payload) {
        let ano = sessionStorage.getItem("anoSelecionado");
        return axios.post('nota/periodo/encerrar/ou/reabrir/'+payload.periodo+'/'+payload.valor+'/'+ano);
    }
    static async removerNota (payload) {
        return axios.delete('nota/apagar/'+payload.id+'/'+payload.tipo);
    }
    static async alterarRetidoDependencia (payload) {
        return axios.put('nota/alunos/mudar/situacao/retido/'+payload.id);
    }
}
